
:root {
  --feed-border-radius: 10px;
  --input-border-radius: 6px;

  // colors
  --dark-color: #0f1419;
  --dark-color-rgb: 15, 20, 25;
  --light-color: #F7F1EC;
  --light-color-rgb: 253, 236, 224;
  --light-color: #ffffff;
  --light-color-rgb: 255, 255, 255;
  --light-mid-color: #B6B1AC;
  --light-mid-color-rgb: 182, 177, 172;

  // dark theme
  // --bg-color: var(--dark-color);
  // --bg-color-rgb: var(--dark-color-rgb);
  // --text-color: var(--light-mid-color);
  // --text-color-rgb: var(--light-mid-color-rgb);

  // light theme
  // @media (prefers-color-scheme: light) {
    --text-color: var(--dark-color);
    --text-color-rgb: var(--dark-color-rgb);
    --bg-color: var(--light-color);
    --bg-color-rgb: var(--light-color-rgb);
  // }
}
